/* ------------------------------------*\
    #COLORS
\*------------------------------------ */

/**
 * Color Variables
 *
 * These variables should always be aliased as Functional Variables, either at
 * the bottom of this file, or at the top of the Component SCSS that they
 * belong to. This method helps aid the quick reskinning of Components.
 */

// font colors

$white:                             #fff !default;
$black:                             #000 !default;
$red:                               #fb4c12 !default;
$grey-text:                         #6c6c6c !default;

//theme-colors
$theme-yellow:                    #ffe300 !default;

//Button colors

// background colors

$darkgrey-bg:                       #333 !default;
$darkgrey:                          #222 !default;
$transparent:                       transparent !default;
$lightgrey:                        #58595b !default;
