.form__checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  p {
    margin: 0;
    padding-left: 10px;

    span {
      text-decoration: underline;
      padding: 0 4px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.or-seprator {
  position: relative;
  display: flex;
  justify-content: center;

  p {
    margin: 0;
    position: relative;
    z-index: 1;
    padding: 0 10px;
  }
}

.signup-wrapper {
  .social-login-wrapper {
    padding: 0 20px 20px 20px;
  }
}

.login-wrapper {
  .social-login-wrapper {
    padding: 20px 0;
  }
}

.account-info-wrapper {
  .form__alternative p {
    font-weight: normal;
    color: white;
  }
}

.facebook-widget,
.social-login-button {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 5px;
  justify-content: center;
  padding: 10px;
  margin: 45px 0;
  height: 40px;

  &:hover {
    cursor: pointer;
  }

  img {
    max-width: 15px;
  }

  p {
    margin: 0;
    color: #4d6eaa;
    font-weight: bold;
    margin-left: 10px;
    font-size: $font-size - 1px;
  }
}

.form {
  .form__item {
    position: relative;
    height: 55px;
    border-radius: 5px;
    overflow: hidden;

    .label-focused {
      top: 7px;
      font-size: $font-size - 2px;
    }

    input {
      width: 100%;
      margin: 0;
      margin-top: 15px;
      padding: 0 15px;
      font-size: $font-size + 4px;
      font-weight: 500;
      height: 40px;
      background: transparent;
      border: none;
      border-radius: 0;
      z-index: 1;
      position: relative;
      font-family: $font-family;

      &:focus {
        outline: none;
      }

      &:focus ~ .floting-label {
        top: 7px;
        font-size: $font-size - 2px;
      }
    }

    input:not(:placeholder-shown) ~ .floting-label {
      top: 7px;
      font-size: $font-size - 2px;
    }

    p {
      margin: 0;
      position: absolute;
      top: 18px;
      font-weight: 500;
      left: 15px;
      font-size: $font-size + 1px;
      white-space: normal;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: block;
      transition: 0.2s ease all;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all;
    }

    .empty-validation-text {
      top: 25px;
      font-weight: 500;
    }
  }

  .empty-validation-text-field {
    top: 25px !important;
    margin: 0;
    position: absolute;
    font-weight: 500;
    left: 15px;
    font-size: $font-size + 1px;
    z-index: 1;
    padding-bottom: 8px;
    min-width: 90px;
  }
}