/* ------------------------------------*\
    #HEADINGS
\*------------------------------------ */

//Section heading

.rail-heading {
  font-size: $font-size + 4px;
  font-weight: 600;
  color: #fff;
  margin: 0;
  padding: 0;
}
