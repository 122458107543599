.layout {
  flex-shrink: 0;
}

.layout-item {
  position: relative;

  &:hover {
    .img-holder__img-container {
      // height: calc(100% + 5px)
    }

    @include respond-to($large-breakpoint) {
      transform: scale(1) !important;
    }
  }
}

.layout-item {
  .free-tag {
    position: absolute;
    top: 0;
    left: 0;
    background: #f7f72a;
    font-size: 12px;
    color: black;
    z-index: 1;
    border-radius: 2px;
    font-family: Blender Pro;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.8px;
    text-align: right;
    padding: 3px 5px 3px 5px;
  }
}

.grid .layout-item {
  margin-bottom: 30px;

  @media (max-width: 1440px) and (min-width: 1025px) {
    margin-right: 14.9px;
  }

  @include respond-to($medium-breakpoint) {
    margin-bottom: 20px;
  }
}

.layout-item__top-title {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: $font-size - 3px;
  padding: 4px 2px;
  flex-direction: column;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    margin: 0;
  }

  .label-only {
    white-space: normal;
    text-align: center;
  }
}

.rail-item-info_icon {
  &:hover {
    opacity: 0.7;
  }
}

.layout-item__premium-tag {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 8px 5px 10px;
  background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 1));

  p {
    font-size: 15px;
    margin: 0;
    font-weight: 600;
  }
}

.layout-item__title {
  left: 0;
  width: 100%;
  white-space: pre-wrap;

  &,
  span {
    font-family: $font-family-secondary;
    font-size: $rail-itemName-font-size;
    cursor: pointer;
    word-break: break-word;

    @include respond-to($medium-breakpoint) {
      font-size: $rail-itemName-font-size - 1px;
    }
  }
}

.layout-item__title---inside-img {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000c9 100%);
  display: flex;
  align-items: flex-end;
  height: auto !important;
  padding: 70px 30px 30px;

  @include respond-to($wide-breakpoint) {
    padding: 50px 20px 20px;
  }

  &,
  span {
    font-size: $font-size * 2;
    font-family: $font-family;
    cursor: pointer;

    @include respond-to($wide-breakpoint) {
      font-size: $font-size + 10px;
      height: auto !important;
    }

    font-size: 20px !important;
  }
}

.img-holder {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.img-holder__img-container {
  width: 100%;
  height: 100%;
  background-size: cover;
  transition: background-size 2s ease-in;
  background-position: center;
  position: relative;

  .details-section-player &,
  .playerContainer & {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;

    &:hover {
      cursor: default;
    }

    img {
      width: 100%;
    }
  }
}

/**
 * Overriding background coloe for img-contianer in player
 */
.slick-track .img-holder__img-container {
  .details-section-player &,
  .playerContainer & {
    background-color: transparent;
  }
}

.img-holder__hover-container {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-holder__helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.img-holder__icon {
  vertical-align: middle;

  @media only screen and (min-width: 1025px) {
    height: 60px;
  }

  @media only screen and (min-width: 481px) and (max-width: 1024px) {
    height: 50px;
  }

  @media only screen and (min-width: 0) and (max-width: 480px) {
    height: 45px;
  }
}

.img-holder__icon--play {
  vertical-align: middle;

  @media only screen and (min-width: 1025px) {
    height: 30px !important;
  }

  @media only screen and (min-width: 481px) and (max-width: 1024px) {
    height: 25px !important;
  }

  @media only screen and (min-width: 0) and (max-width: 480px) {
    height: 25px !important;
  }
}

.img-holder__img-container---info {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
