.splash-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  transition: all 0.2s ease-in;
  z-index: 8;
  background: black;
}

.splash-screen {
  width: 100%;
  height: 100%;
  opacity: 0;
  background-position: center;
}

.splash-open {
  z-index: 10;
  opacity: 1;
}

.offline-page {
  z-index: 9999;
}

.spinner-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  opacity: 0;
  top: 0;
  left: 0;
  display: flex;
  background: rgba(0, 0, 0, 0.6);
  align-items: center;
  justify-content: center;

  .details-section-header__actions & {
    position: absolute;
    width: 22px;
    height: 100%;
    background: transparent;
    align-items: flex-start;
  }

  .suggestion-list & {
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
  }

  .confirm-popup__btn & {
    position: relative;
    width: 100%;
    height: 100%;
    background: transparent;
  }

  .splash-wrapper & {
    background: black;
  }

  .cms-page__content & {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .player & {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 0.45s ease-in;
  }
}

.spinner-open {
  z-index: 9;
  opacity: 1;

  .player & {
    z-index: 3;
    transition: all 0.45s ease-in;
  }

  .details-section-header__actions & {
    z-index: 3;
  }
}

.spinner-close {
  display: none;
  transition: all 0.3s ease-in;
}

.app-ver-code {
  // color: #a6a6a6;
  position: fixed;
  z-index: 99;
  text-align: center;
  bottom: 20px;
  width: 100%;
  left: 0;
  font-size: 12px;
  letter-spacing: 1px;
  font-family: $font-family-secondary;
  font-weight: 500;
  display: flex;
  flex-direction: column;

  .spinner-wrapper {
    position: relative;
    width: auto;
    height: auto;
    margin-bottom: 10px;
    background: transparent;
  }

  .settings-popUp & {
    position: relative;
    bottom: 0;
  }
}
