@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='checkbox'] {
    // --active: #b51c1c;
    // --active-inner: #fff;
    --input-border: #cdd9ed;
    --background: #fff;
    --disabled: #f5f9ff;
    --disabled-inner: #e4ecfa;
    --shadow-inner: rgba(18, 22, 33, 0.1);

    height: 21px;
    outline: none;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    cursor: pointer;
    border: 1px solid var(--input-border);
    background: var(--background);
    transition: background 0.3s ease, border-color 0.3s ease;
    border-radius: 5px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      transition: transform 0.3s ease, opacity 0.2s ease, filter 0.3s ease;
      width: 5px;
      height: 9px;
      border: 2px solid var(--active-inner);
      border-top: 0;
      border-left: 0;
      left: 6px;
      top: 2px;
      transform: rotate(20deg);
    }

    &:checked {
      background: var(--active);
      border-color: var(--active);

      &::after {
        filter: drop-shadow(0 1px 2px var(--shadow-inner));
        transition: opacity 0.3s ease, filter 0.3s ease, transform 0.6s cubic-bezier(0.175, 0.88, 0.32, 1.2);
        transform: rotate(43deg);
      }
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.9;
      background: var(--disabled);
    }

    &:not(.switch) {
      width: 21px;

      &::after {
        opacity: 0;
      }

      &:checked {
        &::after {
          opacity: 1;
        }
      }
    }

    &:disabled:checked {
      background: var(--disabled-inner);
      border-color: var(--input-border);
    }

    &:hover:not(:checked):not(:disabled) {
      border-color: var(--input-border-hover);
    }
  }
}