/* ------------------------------------*\
    #FONTS
\*------------------------------------ */
@import '../tools/mixins';

/**
 * Font stacks
 */

$font-family               : 'Blender Pro', sans-serif, Helvetica, Arial;
$font-family-secondary     : 'Open Sans', sans-serif, Helvetica, Arial;
$font-size                 : 15px;
$font-size-secondary       : 10px;
$header-logo-height        : 40px;
$rail-title-font-size      : 26px;
$rail-viewAll-font-size    : 22px;
$max-width                 :1440px !default;
$rail-itemName-font-size   : 14px;

// font weight
$light:300;
$regular: 400;
$medium: 500;
$bold:700;
