@import "../../../styles/settings/typography";

.error-fullscreen-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  padding: 15px;

  .error-fullscreen-header-close-btn {
    text-align: right;
    padding-bottom: 15px;
    margin-right: -15px;
    svg {
      cursor: pointer;
    }
  }

  .error-fullscreen__content {
    text-align: center;
    border-radius: 4px;
    padding: 20px 40px;
    max-width: 425px;
    width: 100%;

    h2 {
      margin: 0;
      font-size: 22px;
    }

    p {
      white-space: normal;
      margin: 10px 0;
      font-family: $font-family-secondary;
    }
  }

  .popup-btn-subtitle {
    font-size: 14px;
    margin-top: 25px;
    font-family: $font-family-secondary;
  }

  .popup-btn {
    padding: 10px 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;

    &:hover {
      cursor: pointer;
    }

    svg {
      margin-right: 8px;
    }
  }

  .popup-btn__negative {
    margin-top: 15px;
  }

  .error-fullscreen__btn,
  .error-fullscreen__faq {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .error-fullscreen__btn {
    margin-top: 60px;
    .error-fullscreen-wrapper---inline-action & {
      flex-direction: row;
    }
  }

  .error-fullscreen__faq {
    margin-top: 25px;

    .popup-btn-subtitle{
        font-size: 12px;
        margin-top: 0;
        margin-bottom: 7px;
    }

    .popup-btn__negative {
        margin-top: 0;
        padding: 8px 25px 7px;
        font-size: 15px;
    }
  }
}
