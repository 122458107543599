.profile-edit-wrapper {
  position: relative;
  button {
    margin: 45px auto 25px;

    @include respond-to($mobile-breakpoint) {
      margin: 30px auto 25px;
    }
  }

  .spinner-wrapper {
    position: absolute;
    height: 100%;
  }
}

.change-password-wrapper {
  button {
    margin: 45px auto 25px;

    @include respond-to($mobile-breakpoint) {
      margin: 30px auto 25px;
    }
  }
}

// .myAccount-wrapper,
// .profile-edit-page-wrapper,
// .change-password-page-wrapper,
// .signup-page-wrapper,
// .login-page-wrapper,
// .forget-password-page {
//   position: relative;

//   .spinner-wrapper {
//     position: absolute;
//     height: 100%;
// }
// }

.form__item-locked {
  height: 55px;
  border-radius: 5px;
  border: 1px solid transparent !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 0 15px;

  &:hover {
    cursor: not-allowed;
  }

  p,
  h3 {
    margin: 0;
    color: #999;
  }

  p {
    font-size: 13px;
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
  }
}

.login-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .or-seprator {
    margin: 0 auto;
  }

  .facebook-widget,
  .social-login-button {
    max-width: 345px;
    margin: 20px auto 15px;
  }

  .form {
    button {
      max-width: 345px;
      margin: 45px auto;
    }
  }
}

.signup-wrapper {
  .form-top {
    text-align: center;

    h2 {
      margin: 0;
      margin-bottom: 5px;
      font-size: 22px;
    }

    p {
      margin: 0;
    }
  }

  .or-seprator {
    max-width: 400px;
    margin: 0 auto;
    margin-bottom: 40px;
  }

  .facebook-widget,
  .social-login-button {
    max-width: 345px;
    margin: 20px auto 15px;
  }

  .form {
    button {
      margin: 40px auto 25px;
    }
  }
}

.account-recovery-wrapper,
.account-info-wrapper,
.forgot-password-wrapper {
  .form-top {
    text-align: center;

    h2 {
      margin: 0;
      margin-bottom: 5px;
      font-size: 22px;
    }

    p {
      margin: 0;
    }
  }

  .form {
    margin-top: 45px;

    @include respond-to($mobile-breakpoint) {
      margin-top: 30px;
    }

    button {
      margin: 45px auto 25px;

      @include respond-to($mobile-breakpoint) {
        margin: 30px auto 25px;
      }
    }
  }
}

.form-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 50px;
  width: 100%;

  @include respond-to($mobile-breakpoint) {
    padding: 40px 20px;
  }

  .form__alternative {
    text-align: center;

    p {
      margin: 0;
    }

    a {
      font-weight: bold;
      margin-top: 5px;
      display: inline-block;
      font-size: 17px;
    }
  }

  .form__item {
    margin-bottom: 15px;
  }

  .form {
    /* Change Autocomplete styles in Chrome */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      border: 1px solid transparent;
      -webkit-text-fill-color: white;
      -webkit-box-shadow: 0 0 0 1000px transparent inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    h2 {
      margin: 0;
      text-align: right;
      font-size: 15px;
    }

    .validation-Error {
      .empty-validation-text {
        display: none;
      }

      .label-focused {
        top: 18px;
        font-size: 16px;
      }
    }

    .date-picker-wrapper {
      cursor: pointer;
      margin-bottom: 15px;
      position: relative;
      height: 55px;
      border-radius: 5px;
      border: 1px solid transparent;

      .react-date-picker__inputGroup__leadingZero {
        font-size: 16px;
        font-weight: 500;
      }

      button {
        margin: inherit !important;
      }

      .react-calendar {
        box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.45);
        border: none;

        .react-calendar__navigation {
          margin-bottom: 0;
        }
      }

      .react-calendar__navigation__label {
        font-weight: 600;
        font-size: 14px;
      }

      .date-picker-label {
        margin: 0;
        position: absolute;
        top: 18px;
        font-weight: 500;
        left: 15px;
        font-size: 16px;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
        z-index: 2;
        padding-bottom: 8px;
        font-style: italic;
      }

      .label-focused {
        top: 7px;
        font-size: 13px;
        padding-bottom: 0;
        font-style: normal;
      }

      .react-date-picker__inputGroup {
        margin-top: 25px;
        padding: 0 15px;

        input {
          font-size: 16px;
          font-weight: 500;

          &:focus {
            outline: none;
          }

          span {
            font-weight: 500;
          }
        }
      }

      .react-calendar__tile {
        color: inherit;
        height: inherit;
        font-weight: 600;
      }

      .react-date-picker__calendar {
        z-index: 5;
      }

      .react-calendar__tile:disabled {
        background-color: #656565;
        color: white;
      }

      .react-date-picker {
        width: 100%;

        .react-date-picker__wrapper {
          width: 100%;
          border: none;
        }
      }

      .react-calendar__month-view__weekdays {
        font-weight: 600;

        abbr {
          text-decoration: none;
        }
      }
    }

    .button {
      height: 45px;
      display: block;
      font-weight: 500;
      max-width: 300px;
      transition: all 0.3s ease-in-out;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.48);
    }

    .sumbit-bnt-active {
      .button {
        box-shadow: 0 6px 8px 2px rgba(0, 0, 0, 0.48);
        // background: linear-gradient(180deg, #b61c1c 0%, #830f0f 100%);
        transition: all 0.3s ease-in-out;
      }
    }

    .select-box-wrapper {
      position: relative;
      border-radius: 5px;
      border: 1px solid transparent;

      #gender-selectbox-label {
      }

      .label-focused {
        font-style: normal !important;
      }

      .selectBoxInput {
        z-index: 1;
        position: relative;
      }

      .selectBox #dropdown > div {
        color: white;
        font-weight: 500;
      }

      p {
        margin: 0;
        position: absolute;
        top: 18px;
        font-weight: 500;
        left: 15px;
        font-size: 16px;
        transition: 0.2s ease all;
        font-style: italic;
      }

      .label-focused {
        top: 7px;
        font-size: 13px;
        font-style: normal;
      }
    }

    .selectBox {
      height: 55px;
      border-radius: 5px;

      #dropdownIconHolder {
        top: -9px;
        right: 9px;
      }

      #textArea {
        font-weight: 500;
        padding: 0 15px;
        font-size: 16px;
      }

      #dropdownIcon {
        border-color: #fff transparent;
      }

      .selectContainer {
        width: 100%;
      }

      #input {
        padding: 10px 0;
        width: 100%;
        margin-top: 17px;
        border: none;
        height: 35px;
      }
    }
  }
}

.toggle-password-view {
  right: 0;
  position: absolute;
  top: 0;
  height: 55px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &:hover {
    cursor: pointer;
  }
}
.epg-container {
  .epg-wrapper {
    max-width: 1440px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 36px 30px;
    padding-bottom: 0px;
    .form {
      width: 630px;
      display: flex;
      justify-content: space-between;
      .date-picker-wrapper {
        width: calc(50% - 10px);
        .react-date-picker__calendar {
          z-index: 5;
        }
        .react-date-picker__inputGroup {
          margin-top: 2px !important;
          padding: 13px 15px;
        }
        .react-date-picker__calendar-button {
          outline: none !important;
        }
      }
    }
    .heading {
      height: 22px;
      float: left;
      p {
        font-size: 21px;
      }
    }
  }
  > .main-container {
    height: calc(100% - 119px - 106px); // 100% - heightOf(footer) - heightOf(date container)
    .displayed-date {
      padding: 10px 30px;
      padding-bottom: 17px;
      p {
        font-size: 21px;
        margin: 0;
      }
    }
    .container {
      max-height: calc(100% - 51px - 0px); // 100% - heightOf(displayed-date) - bottom space
    }
  }
}
