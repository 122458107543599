.inner-page {
  .fav-del-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    display: none;

    .fav-del-btn___wrapper {
      border-radius: 3px;
      border-radius: 40px;
      font-family: $font-family-secondary;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.75);

      p {
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        padding-left: 5px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .favourites-edit-mode {
    .fav-del-btn {
      display: flex;
    }
  }
}


.listing-page {
  padding-top: 15px;

  @include respond-to($medium-breakpoint) {
    padding-top: 0;
  }

  .layout {
    max-width: $max-width;
    width: 100%;
    margin: 0 auto;
  }
}

.favouritesPage-header {
  -webkit-user-select: none; 
  -ms-user-select: none;
  user-select: none; 
  .layout__title {
    visibility: visible;
  }
}