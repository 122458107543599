/* ------------------------------------*\
    #LINKS
\*------------------------------------ */

a {
  &:hover,
  &:active,
  &:visited,
  &:link {
    text-decoration: none;
  }

  &:hover {
    cursor: pointer;
  }
}

.view-all-link {
  font-size: $font-size - 1px;
  color: white;
  margin: 0;
  padding: 0;
}
