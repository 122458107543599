.toggle-switch {
    .switch {
        position: relative;
        display: inline-block;
        width: 53px;
        height: 25px;
      }
      
      .switch input { 
        opacity: 0;
        width: 0;
        height: 0;
      }
      
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-transition: .4s;
        transition: .4s;
        
      }
      
      .slider:before {
        position: absolute;
        content: "";
        height: 18px;
        width: 18px;
        left: 4px;
        bottom: 4px;
        -webkit-transition: .4s;
        transition: .4s;
        box-shadow: 0 0 0.25em rgba(0,0,0,.3);
      }
      
      input:checked + .slider {}
      
      input:focus + .slider {
        box-shadow: 0 0 0.25em rgba(0,0,0,.3);
      }
      
      input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
      
      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }
      
      .slider.round:before {
        border-radius: 50%;
      }
}