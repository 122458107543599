* {
  box-sizing: border-box;
}

html,
body {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  min-height: 100%;
  min-width: 320px;
  padding: 0;
  margin: 0;
  font-size: 14px;
  max-height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.slick-track {
  margin-left: 0 !important;
  margin-right: 0 !important;
}