/* 1% */

/* Extra small devices (portrait phones, less than 576px) */
.menuOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #212121a1;
}

.menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #222;
  padding: 15px;
}

.menu .menuHeader {
  height: 200px;
}

.menu .menuFooter {
  height: 100px;
}

.menu .menuHeader,
.menu .menuFooter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu .menuItemContainer {
  flex: 1 1 auto;
  overflow-y: auto;
}

.menu .menuItem {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu .menuLogo {
  width: 141px;
  height: 20px;
}
