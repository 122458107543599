/* ------------------------------------*\
    #LIST
\*------------------------------------ */

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.slick-slider {
  .slick-slide > div {
    height: 100%;
    display: flex;
  }

  .slick-list {
    overflow: visible;
   }

  .slick-arrow {
    &::before {
      
    }
  }

  .slick-prev {
    left: -30px;
    // background: #000;
    // background: -moz-linear-gradient(left, #000 0, rgba(19, 19, 19, 0) 100%);
    // background: -webkit-gradient(left top, right top, color-stop(0, #000), color-stop(100%, rgba(19, 19, 19, 0)));
    // background: -webkit-linear-gradient(left, #000 0, rgba(19, 19, 19, 0) 100%);
    // background: -o-linear-gradient(left, #000 0, rgba(19, 19, 19, 0) 100%);
    // background: -ms-linear-gradient(left, #000 0, rgba(19, 19, 19, 0) 100%);
    // background: linear-gradient(to right, #000 0, rgba(19, 19, 19, 0) 100%);
    // filter: progid:dximagetransform.microsoft.gradient(startColorstr='#000000', endColorstr='#131313', GradientType=1);
  }

  .slick-next {
    right: -30px;
    // justify-content: flex-end;
    // background: rgba(19, 19, 19, 0);
    // background: -moz-linear-gradient(left, rgba(19, 19, 19, 0) 0, #000 100%);
    // background: -webkit-gradient(left top, right top, color-stop(0, rgba(19, 19, 19, 0)), color-stop(100%, #000));
    // background: -webkit-linear-gradient(left, rgba(19, 19, 19, 0) 0, #000 100%);
    // background: -o-linear-gradient(left, rgba(19, 19, 19, 0) 0, #000 100%);
    // background: -ms-linear-gradient(left, rgba(19, 19, 19, 0) 0, #000 100%);
    // background: linear-gradient(to right, rgba(19, 19, 19, 0) 0, #000 100%);
    // filter: progid:dximagetransform.microsoft.gradient(startColorstr='#131313', endColorstr='#000000', GradientType=1);
  }
}
