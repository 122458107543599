.notification {
  position: fixed;
  bottom: 150px;
  left: 50%;
  background: #333;
  padding: 15px;
  color: #fff;
  font-size: 16px;
  box-shadow: 0 0 25px rgba(33, 33, 33, 0.5);
  border-radius: 3px;
  transform: translateX(-50%);
  box-sizing: border-box;
  animation: 0.3s down ease 1;
  opacity: 0;
  width: 100%;
  max-width: 500px;
  align-items: center;
  justify-content: space-between;
}

.notification.active {
  bottom: 20px;
  left: 50%;
  opacity: 1;
  animation: 0.15s up ease 1;
  display: flex;
  z-index: 3;
  transform: translateX(-50%);

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    bottom: 20px;
  }

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    bottom: 0;
    max-width: 100%;
  }
}

.notification a {
  color: #b61c1c;
  text-decoration: none;
  font-weight: 600;
  display: block;
  padding: 9px 21px;
  width: auto;
  margin-left: 15px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 14px;
}

.notification .text {
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  flex: 1;

  p {
    margin: 0;
    flex: 1;
    font-size: 15px;
  }
}

.notification .close {
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.1);
}

.notification .ripple {
  overflow: hidden;
  position: relative;
  padding: 12px 25px;
  border-radius: 5px;
  cursor: pointer;
}

.notification .ripple::after {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  display: none;
}

.ripple.rect::after {
  border-radius: 0;
}

.notification .close .text {
  padding: 0;
  text-align: center;
  width: 100%;
  height: 100%;
}

.notification .ripple:hover::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  animation: 0.7s rip ease 1;
}

@keyframes rip {
  0% {
    width: 5px;
    height: 5px;
  }

  75% {
    transform: scale(4);
  }
}

@keyframes up {
  0% {
    display: none;
    bottom: -100px;
    opacity: 0;
  }

  75% {
    bottom: 0;
    opacity: 1;
  }

  100% {
    display: block;
  }
}

@keyframes down {
  0% {
    bottom: 0;
    opacity: 1;
    display: block;
  }

  75% {
    bottom: -100px;
    opacity: 0;
  }

  100% {
    display: block;
  }
}

@keyframes sup {
  0% {
    display: none;
    top: 100%;
    transform: translateY(0%);
    opacity: 0;
  }

  75% {
    top: 100%;
    transform: translateY(-100%);
    opacity: 1;
  }

  100% {
    display: block;
  }
}

@keyframes sdown {
  0% {
    top: 100%;
    opacity: 1;
    display: block;
    transform: translateY(-100%);
  }

  75% {
    top: 150%;
    opacity: 0;
    transform: translateY(0%);
  }

  100% {
    display: block;
  }
}
