.chromecast-mini-wrapper-content {
  display: flex;
  flex-direction: column;
  // color: white;
  padding: 15px 30px;
  align-items: flex-end;
  padding-top: 10px;
  position: relative;

  .player-icon {
    display: block;
  }
  .playerfill-icon {
    display: none;
  }

  .player-icon-container:hover .player-icon {
    display: none;
  }

  .player-icon-container:hover .playerfill-icon {
    display: block;
  }

  .mini-progress-bar {
    position: absolute;
    height: 8px;
    left: 0;
    top: 0;
  }

  .row {
    display: flex;
    flex-direction: row;
    width: 100%;

    .seekTimer {
      padding: 7px 0;
      font-size: $font-size-secondary + 5px;
      font-family: $font-family-secondary;
    }

    .up-arrow {
      position: absolute;
      margin: 0 auto;
      left: 0;
      right: 0;
      cursor: pointer;
      margin-top: 7px;
    }
  }

  .data-wrapper {
    align-items: flex-start;
  }
}

.chromecast-mini-wrapper {
  position: fixed;
  width: 100%;
  max-width: 850px;
  height: 129px;
  left: 50%;
  bottom: 0;
  z-index: 4;
  transform: translateX(-50%);

  #progress_bar_container {
    width: 850px;
    position: absolute;
    top: 0;
    left: 0;
  }

  p,
  h1,
  h2 {
    margin: 0;
  }

  .img-container {
    width: 135px;
    height: 76px;
    // background-color: gray;

    img {
      width: 100%;
      object-position: center;
      height: 100%;
      object-fit: cover;
    }

    p {
      font-size: $font-size + 1px;
      font-weight: 600;
      padding-bottom: 10px;
      text-align: center;
    }
  }

  .details {
    padding-left: 20px;
    flex: 1;

    h2 {
      font-size: $font-size + 1px;
      font-weight: 500;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      display: -webkit-box;
      overflow: hidden;
    }

    h1 {
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      display: -webkit-box;
      overflow: hidden;
      font-size: $font-size + 11px;
    }

    p {
      font-family: $font-family-secondary;
      font-size: $font-size-secondary + 6px;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      display: -webkit-box;
      overflow: hidden;
    }
  }

  .chromecast-mini-spinner {
    position: relative;
    margin: 23px 0;
    margin-left: 20px;

    .spinner-wrapper {
      position: relative;
      width: 30px;
      height: 30px;
      background: transparent;
    }
  }

  .action {
    padding: 23px 0;
    display: flex;
    align-items: center;

    .single {
      margin-left: 25px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

#msg {
  font-weight: normal;
  font-size: 90%;
  margin-left: 200px;
  color: #fff;
  display: none;
  transition: opacity 0 2s;
  -webkit-transition: opacity 0 2s;
}

#top_header_bar {
  width: 100%;
  height: 10px;
  background-repeat: repeat-x;
  margin: 0px;
}

#top_header {
  float: left;
  width: 100%;
  height: 71px;
  background-repeat: repeat-x;
  margin: 0px;
  z-index: 1000;
}

#footer {
  float: left;
  width: 100%;
  height: 81px;
  background-repeat: repeat-x;
  margin: 0px;
}

#copyright {
  float: left;
  width: 300px;
  margin: 10px;
  font-size: $font-size - 2px;
  color: #fff;
}

#footer_content {
  float: left;
  width: 400px;
  margin: 10px;
  font-size: $font-size - 2px;
  color: #fff;
}

#footer_content_link {
  color: #fff;
}

#footer_language {
  float: right;
  width: 200px;
  margin: 10px;
  font-size: $font-size - 2px;
  color: #fff;
}

#logo {
  float: left;
  width: 201px;
  height: 71px;
  margin: 10px 25px 0px 25px;
}

.header_tab {
  float: left;
  height: 31px;
  margin-top: 10px;
  padding: 20px;
  font-family: $font-family-secondary;
  font-weight: bold;
  font-size: $font-size-secondary * 2;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
}

#title_text {
  width: 100%;
  text-align: center;
}

#main_video {
  float: left;
  width: 1100px;
}

#video_image {
  margin-left: 40px;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 0px;
  width: 960px;
  height: 520px;
}

#video_image_overlay {
  margin-left: 40px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 960px;
  height: 540px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0) 72%, rgba(0, 0, 0, 0) 100%);
  z-index: 0;
  display: none;
}

#video_element {
  display: none;
  margin-left: 40px;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 0px;
  width: 960px;
  height: 540px;
  background-color: #000;
}

#media_info {
  display: block;
  clear: both;
  float: left;
  width: 940px;
  height: 116px;
  margin: 5px 0px 0px 40px;
  padding: 10px;
  opacity: 0.9;
  background-color: #dde0e5;
  color: #000;
}

#media_title {
}

#media_subtitle {
  font-family: $font-family-secondary;
}

#feature_toggle_container {
  clear: left;
  float: left;
  margin: 10px;
  font-size: $font-size-secondary + 2px;
  font-family: $font-family-secondary;
}

radio {
  float: left;
  margin: 13px 0px 0px 30px;
  padding: 0px;
  font-size: $font-size-secondary + 8px;
  font-family: $font-family-secondary;
}

#media_control {
  position: relative;
  width: 960px;
  height: 60px;
  z-index: 1000;
  margin: -60px 0px 0px 40px;
  padding: 0px;
  opacity: 0.7;
  background-color: #000;
  font-family: $font-family-secondary;
  transition: opacity 1s;
  -webkit-transition: opacity 1s;
  /* Safari */
}

#media_control:hover {
  opacity: 0.7;
}

#play {
}

#play:hover {
}

#play:press {
}

#pause {
}

#pause:hover {
}

.button {
  margin: 5px;
  font-size: 100%;
}

.volume {
  width: 60px;
  margin-left: 8px;
}

#muteText {
  width: 30px;
  margin-left: 3px;
}

.muteButton {
  font-size: 110%;
}

.imageIcon {
  padding: 3px 0px 0px 0px;
  width: 25px;
}

#progress_bg {
  cursor: default;
  float: left;
  width: var(--progress-bar-width);
  height: 10px;
  background-repeat: repeat-x;
  position: absolute;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.3);
}

#seekable_window {
  position: absolute;
  cursor: pointer;
  z-index: 2;
  float: left;
  width: var(--progress-bar-width);
  height: 10px;
  background-repeat: repeat-x;
  background-color: white;
}

#progress {
  cursor: pointer;
  float: left;
  width: 1px;
  height: 10px;
  z-index: 3;
  background-repeat: repeat-x;
  position: absolute;
  background-color: rgba(208, 2, 27, 1);
  padding-left: -2px;
}

.adMarker {
  position: absolute;
  width: 5px;
  height: 10px;
  pointer-events: none;
  background-color: gold;
}

#unseekable_overlay {
  position: absolute;
  cursor: default;
  float: left;
  width: 0px;
  height: 10px;
  z-index: 4;
  background-color: rgba(255, 255, 255, 0.7);
}

#progress_indicator {
  cursor: pointer;
  float: left;
  draggable: true;
  width: 6px;
  height: 14px;
  z-index: 5;
  margin-top: -2px;
  position: absolute;
  background-color: rgba(208, 2, 27, 1);
}

#progress_indicator:hover {
  margin-top: -3px;
  height: 16px;
}

#castbutton {
  border: none;
  outline: none;
  width: 40px;
  height: 32px;
}

#castbutton:hover {
  // --disconnected-color: #ffd200;
  // --connected-color: #ffd200;
}

#audio_off {
  float: left;
  margin: 10px 15px 10px 15px;
  width: 32px;
  height: 32px;
  display: none;
}

#audio_on {
  float: left;
  width: 32px;
  height: 32px;
  margin: 10px 15px 10px 15px;
  display: block;
  background: red;
}

#audio_bg {
  float: left;
  opacity: 0.1;
  width: 41px;
  height: 124px;
  margin: -115px 0px -10px 8px;
  display: block;
  position: relative;
  z-index: 10;
}

#audio_bg_track {
  float: left;
  opacity: 0;
  width: 16px;
  height: 100px;
  margin: -100px 0px -30px 20px;
  display: block;
  position: relative;
  z-index: 1000;
}

#audio_indicator {
  float: left;
  opacity: 0;
  width: 16px;
  height: 5px;
  margin: -50px 0px -30px 20px;
  display: none;
  position: relative;
  z-index: 1000;
}

#audio_bg_level {
  float: left;
  opacity: 0;
  width: 16px;
  height: 50px;
  margin: -50px 0px -30px 20px;
  display: block;
  position: relative;
  z-index: 1000;
}

#fullscreen_expand {
  float: right;
  width: 32px;
  height: 32px;
  margin: 10px 20px 10px 0px;
  display: block;
  cursor: pointer;
}

#fullscreen_collapse {
  float: right;
  width: 32px;
  height: 32px;
  margin: 10px 20px 10px 0px;
  display: none;
  cursor: pointer;
}

#currentTime {
}

#duration {
}

#live_indicator {
  position: absolute;
  right: 190px;
  top: 22px;
  height: 20px;
  margin: 8px 30px 0px 0px;
  display: none;
}

#skip {
  color: white;
  cursor: pointer;
  display: none;
  opacity: 0.7;
  left: 875px;
  top: 575px;
  width: 55px;
  height: 19px;
  font-family: "Roboto", "Open Sans", "Verdana", Georgia, Serif;
  margin: -60px 0px 0px 40px;
  padding: 10px;
  position: absolute;
  z-index: 1000;
}

#skip:hover {
}

#skip:press {
}

div.imageSub {
  position: relative;
  height: 540px;
}

div.imageSub img {
  z-index: 1;
}

div.imageSub div {
  position: absolute;
  left: 0%;
  right: 0%;
  bottom: 0px;
  padding: 0px;
}

div.imageSub div.blackbg {
  z-index: 2000;
  bottom: 75px;
  left: 300px;
  width: 400px;
  height: 30px;
  color: #000;
  background-color: #000;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  opacity: 0.5;
  font-size: 120%;
  display: none;
  padding: 10px;
}

div.imageSub div.label {
  z-index: 3000;
  bottom: 80px;
  left: 300px;
  width: 400px;
  height: 30px;
  color: white;
  font-size: 120%;
  display: none;
  padding: 10px;
  text-align: center;
}

#carousel {
  width: 990px;
  margin: 20px 10px 10px 40px;
}

.thumb {
  float: left;
  margin: 10px 10px 10px 0px;
  cursor: pointer;
}

.thumbnail {
  margin-right: 10px;
  width: 225px;
  height: 127px;
}

.vertical {
  -webkit-transform: rotate(90deg);
}

.chromecast-expanded-controler-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  // background: black;
  z-index: -1;
  opacity: 0;
  top: 0;
  left: 0;

  .binch-content__text {
    p {
      font-size: 16px;
    }

    h3 {
      font-size: 26px !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box !important;
      line-height: 26px;
    }
  }

  .controler-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    img {
      width: 100vw;
      height: 100vh;
      object-fit: cover;
      object-position: center;
      position: absolute;
      top: 0;
      left: 0;
    }

    .binch-container-wrapper {
      width: 100vw;
      height: 100vh;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .binch__content-wrapper {
        z-index: 11;
        width: 320px;
        border-radius: 4px;
        // color: white;
        position: relative;
        bottom: 0;
        margin-right: 10px;
        border-radius: 9px;
        box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.1);
        display: none;

        .binch__header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 20px;

          p {
            font-family: $font-family-secondary;
            font-size: 16px;
            margin: 0;
          }
        }
        .binch__content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px;
        }
      }

      img {
        width: 100%;
        height: 180px;
        position: relative;
      }
    }

    .binch-container-wrapper---open {
      .binch__content-wrapper {
        display: block;
      }
    }

    .top-control {
      display: flex;
      align-items: center;
      padding: 20px 15px 100px;
      background-image: -webkit-gradient(linear, left bottom, left top, from(transparent), to(rgba(0, 0, 0, 0.8)));
      background-image: linear-gradient(0deg, transparent, rgba(0, 0, 0, 0.8));
      -webkit-transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
      transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
      z-index: 10;
      position: relative;
      justify-content: space-between;

      .player-icon {
        display: block;
      }
      .playerfill-icon {
        display: none;
      }

      .player-icon-container {
        display: flex;
        justify-content: center;
      }

      .player-icon-container:hover .player-icon {
        display: none;
      }

      .player-icon-container:hover .playerfill-icon {
        display: block;
      }

      .title-block {
        // color: white;
        display: flex;
        align-items: center;

        .back-btn {
          padding-right: 25px;
        }
      }

      p {
        margin: 0;
        font-size: $font-size-secondary + 5px;
        white-space: normal;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        font-weight: 600;
        font-family: $font-family-secondary;
      }

      h2 {
        margin: 0;
        font-size: $font-size + 11px;
        white-space: normal;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
      }

      .actions {
        display: flex;
        align-items: flex-end;
        text-align: center;
        margin-right: 25px;

        > div {
          margin-left: 25px;
        }

        .cast-action {
          margin-bottom: 5px;
        }

        p {
          // color: #fff;
          font-family: $font-family-secondary;
          font-size: $font-size-secondary + 2px;
          margin-bottom: 2px;
        }
      }
    }

    .bottom-control {
      display: block;
      z-index: 10;
      position: relative;
      padding: 100px 30px 30px;
      background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(68%, #000));
      background-image: linear-gradient(180deg, transparent, #000 68%);

      .player-icon {
        display: block;
      }
      .playerfill-icon {
        display: none;
      }

      .player-icon-container:hover .player-icon {
        display: none;
      }

      .player-icon-container:hover .playerfill-icon {
        display: block;
      }

      .track-wrapper {
        position: relative;
        margin-bottom: 40px;
      }

      .track {
        width: 100%;
        background: white;
        height: 5px;
        border-radius: 5px;
        position: absolute;
        top: 2px;
      }

      .progress-width {
        height: 10px;
        background: #f7f72a;
        position: relative;
        border-radius: 5px;
        margin-top: -3px;

        &::after {
          position: absolute;
          right: -15px;
          content: "";
          top: -9px;
          background: #f7f72a;
          height: 30px;
          width: 30px;
          border-radius: 20px;
        }
      }

      p {
        font-family: $font-family-secondary;
        margin: 0;
        font-weight: 600;
        // color: #fff;
        font-size: $font-size-secondary + 6px;
      }

      .cast-device {
        text-align: center;
        padding-bottom: 50px;
      }

      .bottom-action {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > div {
          flex: 1;
        }

        .timer {
          text-align: left;
        }

        .control {
          display: flex;
          justify-content: center;

          > div {
            margin: 0 25px;

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }

        .action {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }
  }
}

.chromecast-expanded-controler-wrapper---open {
  opacity: 1;
  z-index: 9;
}

.chrome-cast-controller {
  svg {
    &:hover {
      cursor: pointer;
    }
  }

  .player-audio__content-wrapper {
    // background-color: rgb(43, 43, 43);

    li {
      // background-color: rgba(0, 0, 0, 0);
      // color: rgb(255, 210, 0);
      border-width: 1px;
      border-style: solid;
      // border-color: rgb(255, 210, 0);
      border-image: initial;
    }

    li.active {
      // background-color: rgb(255, 210, 0);
      // color: rgb(29, 29, 29);
    }
  }
}
