/* ------------------------------------*\
    #GLOBAL
\*------------------------------------ */

/**
 * Default global spacing units
 */

$spacing-unit: 10px !default;

$max-width: 1440px !default;

/**
 * Breakpoint widths
 */

$large-mobile-breakpoint: 375px !default; // iphone 6 and up - client request
$mobile-breakpoint: 480px !default;
$small-breakpoint: 599px !default;
$medium-breakpoint: 767px !default;
$wide-breakpoint: 992px !default;
$large-breakpoint: 1024px !default;
$large-desktop-breakpoint: 1200px !default;
$max-content-width: 1290px !default;
$max-desktop-breakpoint-xlg: 2790px !default;
$max-desktop-breakpoint-lg: 2560px !default;

/**
* Animation properties.
*/
$transition: all 0.25s ease-in-out;
