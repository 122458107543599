.footer {
  font-size: 12px;
  margin-top: auto;
  flex-shrink: 0;
  z-index: 2;
}

.footer .row {
  padding: 25px 30px;
  

  @include respond-to($wide-breakpoint) {
    padding: 25px 15px;
  }

  @media only screen and (max-width: 495px){
    padding: 28px 15px 28px 20px;
    // height: 147px;
  }

}

.footer .footer-menu {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  white-space: nowrap;

  @include respond-to($large-breakpoint) {
    justify-content: flex-end;
  }

  // @media only screen and (max-width: 460px) {
  //   justify-content: flex-start;
  // }
}

.footer-menu.appstoreIcons{
  @media only screen and (max-width: 495px){
    img{
      height: 7vw !important;
    }
  }
}

.footer .flexWrapCont {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  // @media only screen and (max-width: 460px){
  //   display: block;
  // }
}

.selectbox-footer {
  margin-right: 30px;

  @include respond-to($large-breakpoint) {
    margin-right: 0;
    margin-bottom: 15px;
   }
}

.footer-left {
  align-items: baseline;

  @include respond-to($large-breakpoint) {
   display: block!important;
  }

  #pageLinks, .footer-link-col span {
    @include respond-to($large-breakpoint) {
      display: block!important;
     }
  }

  .footer-link-col span {
    @include respond-to($large-breakpoint) {
      padding: 0;
      padding-bottom: 12px;
     }
  }
}

.footer .column {
  display: flex;

  span {
    padding-right: 30px;
    font-size: 14px;
    font-family: $font-family-secondary;
    // font-weight: 300;

    @include respond-to($large-breakpoint) {
        // background-color: green;
        padding-right: 0px;
     }
     @media only screen and (max-width: 495px){
      font-size: 13px;

     }
  }
}

.footer .footer-menu.appstoreIcons .column {
  padding: 0;
  padding-left: 10px;

  .footer-link-col{
    @media only screen and (max-width: 495px){
    padding-top: 1px
    }
  }
}

.footer .footer-menu.appstoreIcons img {
  height: 40px;
}

.footer .logo {
  display: inline-flex;
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
}

.footer .logo .emblem {
  display: inline-block;
  padding: 0 5px;
  font-size: 25px;
  background-color: #fee300;
  color: #000;
  line-height: 30px;
}

.footer .logo .emblem ~ div {
  display: inline-block;
  margin-left: 15px;
  color: #fee300;
  vertical-align: text-bottom;
  letter-spacing: 2px;
  padding-top: 1px;
  margin-right: 20px;
}

.footer .footer-menu .column span {
  cursor: pointer;
}

.footer .footer-menu .column:nth-child(1) {
  padding-left: 0;
}

.footer .copyright {
  text-align: right;
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 12px;
  font-family: $font-family-secondary;
  @media only screen and (max-width: 495px){
    margin-top:40px;
    font-size: 10px;
  }

  // @media only screen and (max-width: 460px) {
  //   text-align: left;
  // }
}

.footer select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #333;
  border: 0.5px solid #fee202;
  height: 30px;
  width: 150px;
  border-radius: 5px;
  padding: 0 5px;
}

div.selectbox-footer > div > div:nth-child(1) {
  font-size: 14px;
}

#pageLinks {
  flex-direction: row;

  // .column {
    // @include respond-to($large-breakpoint) {
    //   flex-direction: column;
    //   background-color: pink;

    //   span {
    //     margin-bottom: 20px;
    //   }
    // }
  // }
}

#footerLeftArea {
  flex-direction: row;
}

#appStoreIconsAndCopyright {
  display: block;
}


@media only screen and (min-width: 768px) and (max-width: 1023px) {
  #appStoreIconsAndCopyright {
    margin-left: auto;
  }
}

@media only screen and (min-width: 1024px) {
  #pageLinks {
    flex-direction: row !important;
  }

  #appStoreIconsAndCopyright {
    margin-left: auto;
  }
}


.footer__icons {
  display: flex;
  align-items: center;

  @include respond-to($large-breakpoint) {
    display: block;
  }

  .social-icons {
    margin-right: 5px;
    @media only screen and (max-width: 495px){
      margin-bottom: 10px;
      // padding-top: 5px; 
    }

    @include respond-to($large-breakpoint) {
      margin-right: 0;
      margin-bottom: 15px;
    }

    .column {
      margin-right: 20px;

      @include respond-to($large-breakpoint) {
        margin-right: 0;
        margin-left: 20px;
      }

      // @include respond-to($mobile-breakpoint) {
      //   margin-left: 0;
      // }
  

      img {
        width: 40px;
        @media only screen and (max-width: 495px){ width: 35px; }
      }
    }
  }
}