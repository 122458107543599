/* 1% */

/* Extra small devices (portrait phones, less than 576px) */
.details-section-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.details-section-container > div {
  width: 100%;
  height: 100%;
}

.favourite-processing {
  svg {
    cursor: default!important;
  }
}

.details-section-container .details-section-player {
  cursor: pointer;
  position: relative;
  padding-top: 56%;
  height: auto;
  overflow: auto;
}

.details-section-container .details-section {
  position: relative;
}

.details-section-container .details-section-player img,
.details-section-container .details-section-player-play-icon {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

@media only screen and (max-width: 480px) {
  .detailsSection__title {
    font-size: 22px;
  }

  .detailsSection__subtitle {
    font-size: 14px;
    padding-bottom: 2px;
  }
}

.details-section-container .details-section .details-section-favourites {
  margin-left: auto;
  padding-left: 15px;
}

.details-section-container .details-section-body::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.details-section-container .details-section .details-section-body {
  position: relative;
  flex: 1 1 auto;
  padding-bottom: 15px;
}

.details-section-container .details-section .details-section-body .details-section-rating-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.details-section-container
  .details-section
  .details-section-body
  .details-section-rating-container
  > div:not(:first-child) {
  margin-left: 30px;
}

.details-section-container
  .details-section
  .details-section-body
  .details-section-rating-container
  .details-section-parental-rating {
  width: 80px;
  height: 30px;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.details-section-container .details-section-player-play-icon,
.details-section-container .details-section-release-year,
.details-section-container .details-section-rating {
  display: flex;
  align-items: center;
  justify-content: center;
}

.details-section-container .details-section .details-section-body .details-section-details,
.details-section-container .details-section .details-section-body .details-section-overview-container {
  margin: 0 0 30px 0;
  font-size: $font-size-secondary + 4px;
}

.details-section-container .details-section .details-section-body .details-section-overview-title {
  border-bottom: #ffe300 1px solid;
  padding-bottom: 5px;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 1.1em;
}

.details-section-container .details-section .details-section-body .details-section-details-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.details-section-container
  .details-section
  .details-section-body
  .details-section-details-row
  .details-section-details-title {
  min-width: 125px;
  text-align: left;
  padding-right: 15px;
  font-weight: bold;
}

.details-section-container
  .details-section
  .details-section-body
  .details-section-details-row
  .details-section-details-descriptions
  a {
  text-decoration: none;
}

.details-section-container
  .details-section
  .details-section-body
  .details-section-details-row
  .details-section-details-description
  a:hover {
  text-decoration: underline;
}

/* +70% */

/* Large devices (desktops, 992px and up) */
@media (min-width: 960px) {
  .details-section-container {
    flex-direction: row;
  }

  .details-section-container > div {
    width: 50%;
    height: none;
  }

  .details-section-container .details-section-player {
    padding-top: 28%;
    order: 2;
  }

  .details-section-container .details-section {
    padding-top: 28%;
    height: 100%;
    order: 1;
  }

  .details-section-container .details-section .details-section__media,
  .details-section-container .details-section .details-section__sports {
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
  }
}

.sharePopUp {
  > div {
    max-width: 380px !important;
  }

  .details-page__pop-up-head {
    text-align: left;
    align-items: baseline!important;
    padding: 15px 40px 7px!important;

    p {
      font-family: $font-family-secondary;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      padding-bottom: 3px;
    }

    h1 {
      font-family: $font-family;
      margin: 0;
      font-size: 26px;
      line-height: 26px;
    }
  }

  .sharePopUp__content {
    padding: 0 40px 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 20px;


    .single:not(:last-child){
      margin-bottom: 20px;
    }
    .single:last-child{
      margin-bottom: 25px;
    }

    .single {
      border-radius: 5px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      transition: all 0.2s ease-in-out;
      font-family: $font-family-secondary;
      font-weight: 600;
      button {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        height: 46px;

        &:focus {
          outline: none;
        }

        p {
          margin: 0;
          font-size: 16px;
          padding-right: 13px;
        }
      }

      &:hover {
        cursor: pointer;
        transform: scale(1.05);
      }
    }

    .facebook {
      background: #3a5896;
    }

    .twitter {
      background: black;
      button {
        p {
          padding-left: 7px;
        }
      }
    }

    .normal {
      // background: #f7f72a;
      padding: 14px;
      height: 46px;

      // @include respond-to($wide-breakpoint) {
      //   font-size: 18px;
      // }

      p {
        // color: black;
        margin: 0;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}


.details-page___line {
  border-bottom: 3px solid rgb(51, 51, 51);
}