.details-section__media {
  .details-section-header {
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;

    .detailsSection__title {
      font-size: $font-size + 11px;
      font-weight: 600;
      margin: 0;
    }

    .detailsSection__subtitle {
      font-size: $font-size-secondary + 4px;
      font-family: $font-family-secondary;
      font-weight: 600;
      margin-bottom: 5px;
    }
  }

  .details-section-header__action {
    display: flex;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 30px;

    .action__single {
      flex: 1;

      p {
        margin: 0;
        margin-top: 5px;
        font-size: $font-size - 3px;
        font-weight: 500;
        color: #bfbfbf;
      }
    }
  }

  .details-section-body {
    h3 {
      margin: 0;
      position: relative;
      z-index: 1;
      display: inline-block;
      font-size: $font-size + 1px;
      font-weight: 500;

      &:hover {
        cursor: pointer;
      }
    }

    .details-section-body__header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        margin: 0;
        font-size: $font-size + 5px;
        font-weight: 600;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        white-space: nowrap;

        svg {
          margin-left: 10px;
        }

        &:hover {
          cursor: pointer;
        }
      }

      p {
        margin: 0;
        color: #a6a6a6;
      }
    }

    .details-section-details {
      margin: 0 !important;

      .details-section-details-row {
        margin-top: 0 !important;
        margin-bottom: 15px;

        .details-section-details-title {
          font-weight: 500 !important;
          min-width: 190px !important;
        }

        .details-section-details-description {
          color: white;
        }
      }
    }
  }
}

.details-section__sports {
  .details-section-body {
    border-bottom: 3px solid #333;
  }
}

.details-section__media,
.details-section__sports {
  > div {
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    word-break: break-word;
    width: 100%;
    height: 100%;

    @include respond-to($wide-breakpoint) {
      padding: 20px 20px 0;
    }

    @include respond-to($medium-breakpoint) {
      padding: 15px 15px 0;
    }
  }
}

// .details-section__oveflow {
//   height: 50px !important;
//   background-image: linear-gradient(to bottom, rgba(29, 29, 29, 0), #1d1d1d);
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   width: 100% !important;
//   z-index: 1;
// }

.details-section__media-content::-webkit-scrollbar {
  width: 0 !important;
}

.details-section__sports-content::-webkit-scrollbar {
  width: 0 !important;
}

.details-section__sports-content {
  .details-section-header__channel-logo {
    height: 50px;
    margin-bottom: 20px;
  }

  .detailsSection__title {
    font-size: 25px;
    font-weight: bold;
  }

  .detailsSection__subtitle {
    font-size: 16px;
    font-weight: 500;
  }
}

.imdb-rating-widget {
  display: flex;
  align-items: center;
  background: #2d2d2d;
  padding-right: 10px;
  border-radius: 0 4px 4px 0;

  div {
    padding: 0 5px 0 10px;
  }

  img {
    max-width: 55px;
  }
}

.details-page__pop-up {
  background: rgba(31, 31, 31, 0.85);
  padding: 15px;
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: 0;
  z-index: -1;
  transition: all 0.1s ease-in-out;
  width: 100% !important;

  .details-page__pop-up-wrapper {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none !important;
      width: 0 !important;
    }
  }

  .details-page__pop-up-wrapper {
    transition: max-height 0.3s ease-out; // note that we're transitioning max-height, not height!

    .spinner-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      background: transparent;
      padding-top: 60px;
    }
  }

  .details-page__pop-up-wrapper {
    max-width: 500px;
    width: 100%;
    // background-color: #2b2b2b;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    padding: 30px 40px 40px 40px;
    max-height: 98vh;
    overflow: auto;
  }

  .details-page__pop-up-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    position: relative;
    z-index: 9;
    text-align: left;

    & > div {
      overflow: hidden;
    }

    .episode-data {
      font-family: $font-family-secondary;
      font-size: $font-size-secondary + 4px;
      text-align: left;
      line-height: 20px;

      span {
        position: relative;
        padding-right: 17px;

        &::before {
          content: "";
          position: absolute;
          width: 4px;
          height: 4px;
          top: 9px;
          left: -12px;
          border-radius: 2px;
        }

        &:first-child {
          &::before {
            display: none;
          }
        }
      }
    }

    .player-audio__close {
      min-width: 22px;
      margin-left: 15px;
      padding-bottom: 3px;
      margin-top: 11px;
      padding-left: 2px;
      transition: all 0.2s ease-in-out;

      &:hover {
        cursor: pointer;
        transform: scale(1.15);
      }
    }

    p {
      margin: 0;
      font-size: $font-size + 11px;
      font-weight: 600;
      line-height: 26px;

      .sharePopUp & {
        font-size: $font-size + 5px;
      }
    }
  }

  .details-page__pop-up-content {
    display: flex;

    .details-page__pop-up-content-season-list {
      .active {
      }
    }

    ul {
      li {
        margin-bottom: 10px;

        &:hover {
          cursor: pointer;
        }

        h3 {
          margin: 0;
          text-align: left;
        }
      }
    }
  }
}

.details-page__pop-up---open {
  opacity: 1;
  z-index: 10;

  .details-page__pop-up-wrapper {
    -webkit-animation: scale-in-center 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: scale-in-center 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.details-section-container {
  padding: 0 30px;

  @include respond-to($wide-breakpoint) {
    padding: 0;
    padding-right: 20px;
  }

  @include respond-to(959px) {
    padding: 0;
  }
}

.details-page__content {
  padding: 30px 0 30px;
  flex-shrink: 0;

  @include respond-to($wide-breakpoint) {
    padding: 0;
    padding-top: 20px;
  }

  @include respond-to(959px) {
    padding: 0;
  }

  .details-section__media-content {
    padding: 0 30px 0 0;

    @include respond-to($wide-breakpoint) {
      padding: 0 20px 0;
    }

    @include respond-to(959px) {
      padding: 15px 15px 0;
    }
  }
  .details-section-player{
    .image-loader-skeleton{
      position: absolute;
    }
  }

  .details-section-player .img-holder__img-container {
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    overflow: hidden;

    @include respond-to(959px) {
      border-radius: 0;
      box-shadow: none;
    }
  }

  .layout {
    padding-top: 0;

    .layout__body {
      .grid,
      .rail {
        @include respond-to($wide-breakpoint) {
        }
      }
    }
  }
}

.details-section-header__actions {
  display: flex;

  .action__single {
    margin-left: 40px;
    position: relative;
    min-width: 22px;

    &:first-child {
      margin-left: 10px;
    }

    svg {
      cursor: pointer;
      transform-origin: top;
      transition: all 0.2s ease-in-out;
      &:hover {
        transform: scale(1.15);
      }
    }
  }
}

.details-section-btn__action {
  margin: 22px 0 20px;
  display: flex;
  flex-shrink: 0;

  .action__single {
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    padding: 12px 17px 13px;
    margin-right: 25px;
    align-items: center;

    @include respond-to(375px) {
      padding: 12px 10px 13px;
      margin-right: 10px;
    }

    &:last-child {
      margin: 0;
    }

    .hover {
      display: none;
    }

    &:hover {
      cursor: pointer;

      .normal {
        display: none;
      }

      .hover {
        display: block;
      }
    }

    p {
      margin: 0;
      padding-left: 10px;
      font-weight: 600;
      font-size: $font-size + 1px;

      @include respond-to(375px) {
        padding-left: 5px;
      }
    }
  }
}

.details-section-body {
  margin-top: 10px;
  .description {
    font-size: $font-size-secondary + 4px;
    font-weight: normal;
    line-height: 21px;
    letter-spacing: normal;
    margin: 0;
    font-family: $font-family-secondary;
    margin-bottom: 10px;
    -webkit-line-clamp: 5;
    max-height: 105px;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    display: -webkit-box;
    overflow: hidden;

    @include respond-to(1320px) {
      -webkit-line-clamp: 4;
      max-height: 85px;
    }

    @include respond-to(1230px) {
      -webkit-line-clamp: 2;
      max-height: 42px;
    }

    @include respond-to(1080px) {
      -webkit-line-clamp: 1;
      max-height: 20px;
    }

    @include respond-to(959px) {
      -webkit-line-clamp: 6;
      max-height: initial;
    }
  }
}

.details-page__pop-up---media {
  .details-page__pop-up-head {
    margin-bottom: 0;
    text-align: left;

    p {
      font-size: $font-size + 11px;
    }
  }

  .details-page__pop-up-content {
    display: block;
    text-align: left;
    position: relative;

    .description {
      margin: 20px 0 25px;
      font-size: $font-size-secondary + 4px;
      font-weight: normal;
      line-height: 1.6;
      font-family: $font-family-secondary;
      letter-spacing: normal;
      white-space: normal;
    }

    .details-section-details-row {
      display: flex;
      margin-bottom: 15px;

      &:last-child {
        margin: 0;
      }

      .details-section-details-title {
        font-weight: 500;
        font-size: $font-size + 1px;
        min-width: 190px;
        letter-spacing: 1.33px;

        @include respond-to($wide-breakpoint) {
          min-width: 140px;
        }
      }

      .details-section-details-description {
        font-family: $font-family-secondary;
        font-size: $font-size-secondary + 4px;
        font-weight: normal;
      }
    }
  }
}

.details-section-header__badges {
  display: flex;
  justify-content: space-between;

  ul {
    white-space: nowrap;
    div {
      &:nth-child(2) {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 4px;
          height: 4px;
          top: 9px;
          left: -14px;
          border-radius: 2px;
          background: #a6a6a6;
        }
      }
    }
  }

  span:not(:first-child) {
    position: relative;
    padding-right: 17px;
    font-family: $font-family-secondary;
    font-size: $font-size-secondary + 4px;
    font-weight: 600;

    &::before {
      content: "";
      position: absolute;
      width: 4px;
      height: 4px;
      top: 8px;
      left: -11px;
      border-radius: 2px;
      background: #a6a6a6;
    }
  }

  // .releaseYear {
  //   &::after {
  //     content: "";
  //     position: absolute;
  //     width: 4px;
  //     height: 4px;
  //     top: 8px;
  //     right: 4px;
  //     border-radius: 2px;
  //     background: #a6a6a6;
  //   }
  // }

  span:first-child {
    position: relative;
    padding-right: 17px;
    font-family: $font-family-secondary;
    font-size: $font-size-secondary + 4px;
    font-weight: 600;
  }

  ul {
    display: flex;
    margin-top: 5px;
    align-items: center;

    p {
      margin: 0;
      margin-right: 5px;
      font-family: $font-family-secondary;

      &:hover {
        cursor: default;
      }
    }

    li:nth-child(2) {
      font-weight: 600;
      font-family: "Montserrat", sans-serif;
      border-radius: 3px;
      font-size: 13px;
      padding: 0px 2px;
      line-height: 17px;
      margin-right: 17px;
      text-align: center;
      min-width: 30px;
      position: relative;

      &:hover {
        cursor: default;
      }
    }
    .live {
      color: #1d1d1d;
      background: #ffe400;
      border-color: #ffe400;
    }

    .filled {
      color: #1d1d1d;
    }

    li {
      font-weight: 600;
      font-family: "Montserrat", sans-serif;
      border-radius: 3px;
      font-size: 13px;
      padding: 0px 2px;
      line-height: 17px;
      margin-right: 15px;
      text-align: center;
      min-width: 30px;
      position: relative;
      display: inline-block;

      &:hover {
        cursor: default;
      }
    }
  }
}

.details-page__pop-up-wrapper---empty {
  min-height: 300px;
}

.season-selector {
  .details-page__pop-up-wrapper {
    padding: 0;
    max-width: 380px;

    .details-page__pop-up-head {
      padding: 30px 40px 0;
      align-items: baseline;

      .title-wrapper {
        width: 78%;
        word-wrap: break-word;
      }

      // p {
      //   white-space: normal;
      //   overflow: visible;
      //   word-break: break-all;
      // }
    }

    .details-page__pop-up-content {
      padding-bottom: 22px;

      .details-page__pop-up-content-season-list {
        display: block;
        width: 100%;
        li {
          display: block;
          padding: 8px 40px;
          font-size: $font-size + 5px;
          font-family: $font-family;
          font-weight: 600;
          margin: 0;
        }
      }
    }
  }
}

.details-section___skeleton-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-right: 30px;

  @include respond-to($wide-breakpoint) {
    padding: 20px 20px 0;
  }

  @include respond-to(959px) {
    padding: 15px 15px 0;
    margin-bottom: 20px;
    position: relative;
  }

  p {
    margin: 10px 0;
  }

  .play {
    margin: 22px 0 20px;
  }

  .content {
    line-height: 30px;
    margin-top: 20px;
  }
}
