.catalog-banner-slider {
  padding-top: 15px;

  .catalog-banner-slider__single {
    &:focus {
      outline: none;
    }
  }
}

.empty-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  padding: 0 20px;
  flex: 1;
}
